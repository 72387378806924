@use "variables";

p-inputmask {
  .p-inputtext {
    width: 100%;
  }
}

db-input-mask.ng-dirty.ng-invalid {
  p-inputmask > .p-inputtext {
    border-color: variables.$red;
  }
}

.p-splitbutton.p-button-rounded > .p-button {
  border-radius: 8px;
}

/* Calendar */
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  color: #979797;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  /* Month and year headers - bold */
  font-weight: 500;
  font-family: ProximaNovaBold, serif;
  font-size: 16px;
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
  /* Highlight of Today */
  color: #f5f6fd;
  background: #7646ff;
}

.p-datepicker table td > span.p-highlight {
  /* Highlight of Selected date */
  color: #f5f6fd;
  background: #7646ff;
}

.p-datepicker:not(.p-disabled)
  table
  td
  span:not(.p-highlight):not(.p-disabled):hover {
  background: #7646ff;
  color: #f5f6fd;
}

.p-datepicker table td.p-datepicker-today > span {
  background: #f5f6fd;
}

.p-datepicker-month,
.p-link.p-datepicker-month {
  /* Add spacing between month and year in calendar header */
  margin-right: 8px;
}

/* End of Calendar */

/* Skeleton background color */
.p-skeleton {
  background-color: #eff1f4;
}

/* Spacing between radio button and label */
p-radiobutton + label {
  margin-left: 8px;
}

.p-datatable .p-datatable-thead > tr > th {
  font-weight: 500;
  font-family: "ProximaNovaBold";
}

.p-datatable .p-sortable-column:focus {
  box-shadow: none;
  outline: 0 none;
}

.p-datepicker table th > span {
  font-weight: 500;
  font-family: "ProximaNovaBold";
}

strong {
  font-weight: 500;
  font-family: "ProximaNovaBold";
}

/* Sidebar */
.p-sidebar-left.p-sidebar-lg,
.p-sidebar-right.p-sidebar-lg {
  width: calc(100% - 122px) !important;
}

.p-sidebar-left.p-sidebar-md,
.p-sidebar-right.p-sidebar-md {
  width: 50% !important;
}

.p-sidebar-left.p-sidebar-sm,
.p-sidebar-right.p-sidebar-sm {
  width: 25% !important;
}
/* End of sidebar */

/* Dropdown */
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 1.75rem;
  margin-right: -1.75rem;
}
.p-card-footer {
  position: relative;
}

/* Autocomplete */
.db-autocomplete-users-multi-select,
.db-autocomplete-users-single-select {
  .p-autocomplete-multiple-container {
    width: 100%;
  }

  .p-autocomplete-panel .p-autocomplete-items {
    padding: 0;
  }

  .p-autocomplete-panel .p-autocomplete-item {
    padding: 0;
  }

  .p-autocomplete-panel .p-autocomplete-item.p-focus {
    background: #f5f6fd;
  }

  .p-autocomplete-panel
    .p-autocomplete-items
    li:not(.p-autocomplete-empty-message):first-child {
    padding-top: 4px;
  }

  .p-autocomplete-multiple-container {
    max-height: 300px;
    overflow-y: auto;
  }

  .db-autocomplete-users-input::placeholder {
    color: #979797;
  }

  .db-autocomplete-users-show-selected-items-outside {
    .p-autocomplete-token {
      display: none;
    }
  }
}

.db-autocomplete-users-multi-select {
  .p-autocomplete-token {
    padding: 0;
    background: #f5f6fd;
    color: inherit;
    border-radius: 20px;
  }

  .p-autocomplete-clear-icon,
  .p-autocomplete-loader {
    // When selected items go on few lines, the clear icon and loader should be always on the first line
    top: 0;
    margin-top: 12px;
  }
}

.db-autocomplete-users-single-select {
  .p-autocomplete-multiple-container {
    // Prevents cursor for typing in the input field being on separated line
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .db-autocomplete-users-input {
    width: 100%;
  }

  .p-autocomplete-token {
    padding: 0;
    background: transparent;
    color: inherit;
    border-radius: 20px;
  }

  /* Hide (x) icon on selected item */
  .p-autocomplete-token-icon {
    display: none;
  }
}

db-autocomplete-users {
  &.db-border-critical {
    .p-inputtext {
      @apply border-borders-critical;
    }
  }
}

@use "variables";

.d-calendar-icon {
  // TODO: replace with template after version upgrade of Prime NG
  height: 1rem;
  width: 1rem;
  background-image: url("/assets/icons/calendar.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.date-range-picker:not(.icon-in-container) {
  .p-calendar-w-btn .p-datepicker-trigger {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .p-calendar-w-btn .p-button:enabled:hover.p-datepicker-trigger {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

db-office-analytics-filters {
  p-calendar {
    .p-inputtext {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
}

.date-range-picker.icon-in-container {
  .p-calendar-w-btn {
    .p-inputtext {
      border-right: 0;
    }

    .p-datepicker-trigger {
      border: 1px solid variables.$middle-gray;
      border-left: 0;
      cursor: text;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:hover {
      .p-inputtext {
        border-color: variables.$db-purple;
      }
      .p-datepicker-trigger {
        border-color: variables.$db-purple;
        background-color: transparent;
      }
    }
  }
}

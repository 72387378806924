:root {
  /* Avatars */
  --avatars-avatar-1: #33292f;
  --avatars-avatar-2: #8c4942;
  --avatars-avatar-3: #8b6841;
  --avatars-avatar-4: #3a6ae9;
  --avatars-avatar-5: #d671c0;
  --avatars-avatar-6: #575400;
  --avatars-avatar-7: #408aaa;
  --avatars-avatar-8: #41728b;
  --avatars-avatar-9: #696969;
  --avatars-visitors: #e07800;
  --avatars-selected: #7646ff;
  --avatars-plus-more: #e7e7e7;
  --avatars-default: #e9edfd;
  --avatars-user-group: #7a8dd6;
  --avatars-anonymous: #8f8f8f;

  /* Borders */
  --borders-light: #e7e7e7;
  --borders-hover: #7646ff;
  --borders-focus: #7646ff;
  --borders-critical: #ff5455;
  --borders-critical-web: #e7e7e7;
  --borders-time-now: #d671c0;
  --borders-dark: #bcbcbc;
  --borders-timeslot: #8f8f8f;
  --borders-darker: #979797;
  --borders-calendar-mobile: #cdd7ff;
  --borders-hover-darker: #d689c5;
  --borders-error-kiosk: #fc7373;
  --borders-darker-kiosk: #424242;
  --borders-light-kiosk: #ffffff;
  --borders-dark-kiosk: #979797;
  --borders-lighter: #ffffff;

  /* Buttons */
  --buttons-primary: #7646ff;
  --buttons-tertiary: #565656;
  --buttons-critical: #ff5455;
  --buttons-primary-hover: #5b26f3;
  --buttons-critical-hover: #e92e2f;
  --buttons-tertiary-hover: #000000;
  --buttons-primary-click: #4920c0;
  --buttons-critical-click: #c52223;
  --buttons-tertiary-click: #000000;
  --buttons-icon-mobile: #8f8f8f;
  --buttons-icon-mobile-active: #7646ff;
  --buttons-icon: #8f8f8f;
  --buttons-icon-hover: #7646ff;
  --buttons-icon-click: #4920c0;
  --buttons-special-tapped-kiosk: #696969;
  --buttons-special-kiosk: #424242;
  --buttons-secondary-tapped-kiosk: #979797;
  --buttons-primary-kiosk: #ffffff;

  /* Containers */

  --containers-dark-primary: #f5f6fd;
  --containers-light: #ffffff;
  --containers-modal-background-23: #000000;
  --containers-tooltip: #565656;
  --containers-dark-secondary: #7646ff;
  --containers-timeslot: #7a8dd6;
  --containers-timeslot-others: #f8f8f8;
  --containers-dark-tertiary: #e9edfd;
  --containers-mobile-drawer-line: #979797;
  --containers-chips-kiosk: #2c2c2c;
  --containers-selection-kiosk: #131313;
  --containers-dropdown-kiosk: #2c2c2c;
  --containers-input-kiosk: #424242;
  --containers-not-booked-kiosk: #565656;
  --containers-booked-kiosk: #565656;
  --containers-occupied-now-kiosk: #fc7373;
  --containers-pending-now-kiosk: #e59a07;
  --containers-free-now-kiosk: #2acfb2;
  --containers-skeleton-loading: #e7e7e7;

  /* Icons */
  --icons-primary: #8f8f8f;
  --icons-information: #4d64c4;
  --icons-success: #09644b;
  --icons-warning: #e07800;
  --icons-critical: #e92e2f;
  --icons-active-hover: #7646ff;
  --icons-inactive: #bcbcbc;
  --icons-dark-background: #ffffff;
  --icons-success-on-white-bg: #3da491;
  --icons-primary-on-colored-bg: #565656;
  --icons-dark-kiosk: #979797;
  --icons-light-kiosk: #f8f8f8;

  /* Inline Messages */
  --inline-messages-error: #fae0e0;
  --inline-messages-information: #e9edfd;
  --inline-messages-warning: #fff3cc;
  --inline-messages-success: #d3eeec;

  /* Marketing */
  --marketing-upgrade: #c68006;

  /* Office Roles */
  --office-roles-first-aider: #3da491;
  --office-roles-fire-responder: #ff5455;
  --office-roles-key-holder: #e07800;
  --office-roles-evacuation-assistant: #3a6ae9;

  /* Page Backgrounds */
  --page-backgrounds-darker: #f5f6fd;
  --page-backgrounds-light: #ffffff;
  --page-backgrounds-kiosk: #1c1c1f;

  /* Progress */
  --progress-background: #e9edfd;
  --progress-generic: #7646ff;
  --progress-low: #ff5455;
  --progress-medium: #e07800;
  --progress-high: #3da491;

  /* Resources */
  --resources-desks: #4d64c4;
  --resources-meeting-rooms: #83641b;
  --resources-parking-spots: #b92ec7;
  --resources-others: #8f8f8f;

  /* Schedules */
  --schedules-travel: #3da491;
  --schedules-mobile-office: #e07800;
  --schedules-office: #916bff;
  --schedules-absent: #1e88e4;
  --schedules-custom-1: #d671c0;
  --schedules-custom-2: #056899;
  --schedules-custom-3: #8c4942;
  --schedules-custom-4: #33292f;
  --schedules-custom-5: #8f8f8f;
  --schedules-custom-7: #6c9a60;
  --schedules-custom-8: #d45e6a;
  --schedules-unknown: #8f8f8f;

  /* Separators */
  --separators-separators: #e7e7e7;

  /* Statuses */
  --statuses-inactive: #8f8f8f;
  --statuses-active: #3da491;
  --statuses-check-in-due: #ff5455;
  --statuses-checked-in: #3da491;
  --statuses-attending: #3da491;
  --statuses-maybe: #8f8f8f;
  --statuses-not-attenting: #ff5455;
  --statuses-booked: #7646ff;
  --statuses-upcoming: #7646ff;
  --statuses-fulfilled: #3da491;
  --statuses-meeting-room-free: #d3eeec;
  --statuses-meeting-room-fusy: #fae0e0;
  --statuses-pending: #e07800;
  --statuses-on-site: #3da491;
  --statuses-pending-kiosk: #e59a07;
  --statuses-occupied-kiosk: #fc7373;
  --statuses-free-kiosk: #2acfb2;

  /* Tables */
  --tables-cell: #ffffff;
  --tables-header: #e9edfd;

  /* Text */
  --text-headings: #000000;
  --text-body: #565656;
  --text-links-primary: #7646ff;
  --text-links-secondary: #000000;
  --text-inactive: #bcbcbc;
  --text-on-colored-backgrounds: #ffffff;
  --text-link-destructive: #ff5455;
  --text-permanent-light: #ffffff;
  --text-permanent-dark: #000000;
  --text-inactive-kiosk: #696969;
  --text-error-kiosk: #fc7373;
  --text-secondary-kiosk: #979797;
  --text-on-white-bg-kiosk: #565656;
  --text-heading-kiosk: #ffffff;
  --text-body-kiosk: #e7e7e7;
  --text-text-link-kiosk: #ffffff;
  --text-status-kiosk: #000000;
  --text-title-kiosk: #ffffff;
  --text-placeholder: #bcbcbc;
  --text-hyperlink-kiosk: #a98aff;

  /* Toast Messages */
  --toast-messages-success: #3da491;
  --toast-messages-warning: #e07800;
  --toast-messages-error: #ff5455;
  --toast-messages-informational: #565656;
}

.p-menu .p-menuitem-icon {
  margin-right: 16px;
  &::before {
    width: 24px;
    height: 24px;
  }
}

.p-menuitem {
  &.text-text-link-destructive {
    .p-menuitem-icon,
    .p-menuitem-text {
      color: var(--text-link-destructive);
    }
  }
}

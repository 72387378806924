@use "variables";

.rounded {
  border-radius: variables.$default-border-radius;
}

.border-gray {
  border: 1px solid variables.$primary-gray;
}

.rounded-top {
  border-radius: variables.$default-border-radius
    variables.$default-border-radius 0 0;
}

@use "variables";

.ui-modal {
  width: 100%;

  .p-card {
    @apply p-3xl;

    &.shorter-top-bottom-padding {
      padding: 42px 44px;
    }
  }

  .p-card .p-card-body {
    padding: 0;
  }

  .p-card-header {
    margin-bottom: 32px;

    h3 {
      color: variables.$black;
      text-align: center;
      word-wrap: break-word;
    }
  }

  .no-body .p-card-header {
    margin-bottom: 0;
  }

  .ui-modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;

    button,
    db-button,
    db-button {
      flex: 1;
      justify-content: center;

      &:nth-child(2) {
        margin-left: 16px;
      }
    }
  }
}

.ui-modal-with-close-button {
  .ui-modal .p-card-header h3 {
    /* For dialog with close button, leave space between the header and the close button */
    margin-right: 40px;
  }
}

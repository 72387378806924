@use "variables";

// The kiosk is still in PROTOTYPE MODE, hence the changes are not added to the design system

.kiosk-high-button {
  button {
    height: 60px !important;
    font-family: variables.$font-bold;
    font-size: 20px;
    font-weight: variables.$font-weight-bold;
    line-height: 20px;
  }
}

.kiosk-mode-exit-form,
.kiosk-mode-booking-form {
  .p-dropdown {
    border: 1px solid variables.$kioskGray1;
    background: variables.$kioskGray2;
    height: 57px;

    &.db-time-picker {
      display: flex;
      align-items: center;
    }
  }

  label {
    font-family: variables.$font-bold;
    font-size: 20px;
    font-weight: variables.$font-weight-bold;
    line-height: 25px;
    color: variables.$white;
  }

  .p-inputtext {
    background: variables.$kioskGray2;
    color: variables.$white;
    border: 1px solid variables.$kioskGray1;
    height: 57px;
    font-size: 16px;

    &.p-dropdown-label {
      display: flex;
      align-items: center;
      border: none;
      height: auto;

      .name {
        color: variables.$white;
      }
    }
  }
  db-time-picker-control .time-setter:not(.am-pm) .p-dropdown.db-time-picker {
    width: 238px;
    margin-right: 13px;
  }
}

.kiosk-dropdown-panel {
  border: 1px solid variables.$kioskGray1;
  background: variables.$kioskGray2;

  .p-dropdown-header {
    background: variables.$kioskDark;
    color: variables.$white;
  }

  .p-inputtext {
    background: variables.$kioskGray2;
    color: variables.$white;
    border: 1px solid variables.$kioskGray1;
    height: 57px;
    font-size: 16px;
  }

  .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    background: variables.$kioskDark;
    color: variables.$white;
  }

  .name {
    color: variables.$white !important;
  }
}

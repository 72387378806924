@use "variables";

db-editor {
  // Show the toolbar bellow the text editor
  .p-editor-container {
    display: flex;
    flex-direction: column-reverse;

    .p-editor-content {
      border-top: 1px solid variables.$middle-gray !important;
      border-bottom: 0px !important;
    }
  }

  .ql-action {
    color: variables.$db-purple !important;
  }

  // Adjust borders because of position of text editor toolbar to be at bottom. Background and border colors.
  .p-editor-container {
    .p-editor-content {
      border: 1px solid variables.$middle-gray !important;
      border-bottom: 0 !important;
      border-bottom-right-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
      border-top-right-radius: variables.$default-border-radius !important;
      border-top-left-radius: variables.$default-border-radius !important;
    }

    .p-editor-toolbar {
      background: variables.$white;
      border-top-right-radius: 0px !important;
      border-top-left-radius: 0px !important;
      border-bottom-color: variables.$text-gray !important;
      border-bottom-right-radius: variables.$default-border-radius !important;
      border-bottom-left-radius: variables.$default-border-radius !important;

      &.ql-snow {
        border: 1px solid variables.$middle-gray !important;
        border-top: 0 !important;
      }

      button {
        &:hover,
        &.ql-active {
          svg {
            path,
            line {
              stroke: variables.$db-purple !important;
            }
            rect {
              fill: variables.$db-purple !important;
            }
          }
        }
        svg {
          path,
          line {
            stroke: variables.$dark-gray !important;
          }
          rect {
            fill: variables.$dark-gray !important;
          }
        }
      }
    }
  }

  .p-editor-container .p-editor-content .ql-editor {
    background: variables.$white;
    color: variables.$text-gray;
    border-top-right-radius: variables.$default-border-radius !important;
    border-top-left-radius: variables.$default-border-radius !important;
    font-size: variables.$font-size-14;
    font-family: variables.$font;
  }

  // Hover effect on the border
  .p-editor-container {
    &:hover {
      .p-editor-content {
        border-left: 1px solid variables.$db-purple !important;
        border-right: 1px solid variables.$db-purple !important;
        border-top: 1px solid variables.$db-purple !important;
      }

      .p-editor-toolbar {
        border-left: 1px solid variables.$db-purple !important;
        border-right: 1px solid variables.$db-purple !important;
        border-bottom: 1px solid variables.$db-purple !important;
      }
    }
  }

  // Override the placeholder's font since it's italic and black
  .ql-editor.ql-blank::before {
    left: 12px !important;
    font-style: normal !important;
    color: variables.$dark-gray !important;
    font-family: ProximaNova !important;
    font-size: 1rem !important;
  }

  // Align the cursor and content similar to our other inputs
  .ql-editor p,
  .ql-editor ol,
  .ql-editor ul,
  .ql-editor pre,
  .ql-editor blockquote,
  .ql-editor h1,
  .ql-editor h2,
  .ql-editor h3,
  .ql-editor h4,
  .ql-editor h5,
  .ql-editor h6 {
    margin-left: -4px !important;
  }

  // The popup that opens when you click the link option is opening a bit to the left
  .ql-tooltip.ql-editing {
    left: 12px !important;
    border-radius: variables.$default-border-radius !important;
  }
}
